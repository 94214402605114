body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  width: 275px;
  float: left;
}

.navbar nav {
  padding: 0 10px;
}

.navbar .ms-Nav-groupContent {
  margin-bottom: 20px;
}

.loader-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #555;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0;
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 120px);
  margin: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.logo img {
  height: 25px;
  margin: 10px 0 0 10px;
}

.title {
  flex-grow: 1;
  text-align: center;
  position: relative;
  top: 15px;
  margin: 0;
  font-weight: normal;
}

.version {
  color: #666;
  align-self: center;
  margin-right: 10px;
  font-size: 0.8em;
  position: relative;
  top: 2px;
}

.main-menu .ms-CommandBar {
  padding-right: 7px;
}

.content .graphiql-container {
  width: auto;
}

.content .ag-root-wrapper.ag-layout-normal {
  height: 96%;
}

.content .ag-theme-balham .ag-status-bar {
  font-weight: normal;
  color: #000;
}

.content .ag-theme-balham .ag-status-bar .ag-status-name-value-value {
  font-weight: 600;
}

#total-row-count {
  height: 16px;
}

.case-details {
  margin-left: 30px;
  padding-bottom: 40px;
}

.ag-theme-balham .ag-full-width-container > .ag-row {
  background-color: #ecf0f1;
}

.coachmark-target {
  position: relative;
  left: 290px;
  top: 32px;
}

.content .swagger-ui .scheme-container {
  display: none;
}

/* MJCS */
#case-details {
  background-color: white;
  color: #000088;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  margin: 0px;
  line-height: 110%;
  font-size: 8pt;
}

#case-details table h5 {
  font-size: 13.28px;
}

.Value {
  color: black;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  line-height: 110%; /*line-height set to 110% in order to*/
  width: auto;
  font-size: 8pt;
  font-weight: bold;
  padding-right: 10px;
}

.Prompt {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  line-height: 110%; /*line-height set to 110% in order to*/
  font-size: 8pt;
  color: red;
  padding-right: 5px;
}

.Header {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  color: white;
  background-color: #191970;
  text-align: center;
  font-size: medium;
  line-height: normal;
}

.Subheader {
  /* font-family : Verdana, Geneva, Arial, Helvetica, sans-serif;
   color: black;
   padding-top:10px;
   padding-bottom:10px;
   text-align: center; */
  display: none;
}

.InfoStatement {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-style: italic;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.InfoChargeStatement {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-style: italic;
  color: red;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}

.BodyWindow {
  background-color: #d3d3d3;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  margin: 0px;
  /* line-height: 150%;   */
  width: 630px;

  border-width: thin;
  border: thin solid #191970;

  margin-left: 2%;
  margin-top: 1em;
  font-size: 8pt;
}

.AltBodyWindow1 {
  background-color: #e1e1e1;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  margin-left: 1%;
  margin-right: 1%;

  width: 605px;
  border-width: thin;
  border: thin solid #191970;

  font-size: 8pt;
}

.BodyWindowDcCivil {
  background-color: #d3d3d3;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  margin: 0px;
  /* line-height: 150%;   */
  width: 825px;

  border-width: thin;
  border: thin solid #191970;

  margin-left: 2%;
  margin-top: 1em;
  font-size: 8pt;
}

.AltBodyWindowDcCivil {
  background-color: #e1e1e1;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  margin-left: 1%;
  margin-right: 1%;

  width: 805px;
  border-width: thin;
  border: thin solid #191970;

  font-size: 8pt;
}

hr {
  width: 95%;
}

.FirstColumnPrompt {
  color: red;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  line-height: 110%; /*line-height set to 110% in order to*/
  text-align: right;
  width: 130px;
  font-size: 8pt;
}

table.TableNoSpacingNoPadding,
table.TableNoSpacingNoPadding td {
  padding: 0px;
  border-spacing: 0px;
  vertical-align: top;
  border-collapse: collapse;
}

/*  Style to set font and underline for table heading row  */
.tableHeader {
  /* border: 1px solid #000; */
  border-bottom: 1px solid #000;
  color: black;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  line-height: 110%; /*line-height set to 110% in order to*/
  width: auto;
  font-size: 8pt;
  font-weight: bold;
  padding-right: 10px;
}
.bold {
  font-weight: bold;
}
